.card {
  max-width: 15rem;
  padding: 1rem;
  border-radius: .25rem;
  box-shadow: $shadow-bottom;
  transition: box-shadow .2s ease-out;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    box-shadow: $shadow-hover;
  }

  .title {
    border-bottom: 1px solid rgba($color--dark, .1);
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-normal;
    color: $color--brand-red;
    text-shadow: $text-glow--brand-red;
    text-transform: uppercase;
    padding-bottom: .5rem;
  }

  p {
    padding: 1rem 0;
  }
}
