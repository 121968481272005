// COMMON ARTICLE STYLES
.article {
  padding: 1.25rem;
  
  p { color: $color--dark; }

  &__body {
    a {
      color: $color--neon-mulberry;
      text-decoration: underline;
      &:hover {
        color: darken($color--neon-mulberry, 10%);
      }
    }
  }

  .article__header--title { color: $color--brand-red; }
  .article__header--published-date { font-family: $font-family-serif; }

  // default back button styles (displayed only on "full" mode)
  .article-back-button {
    display: none;
    background: none;
    border: none;

    width: 2rem;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
  }
}

// ARTICLE DISPLAY MODES
// ----- Article Summary (displayMode: "summary")
.article--summary {
  border-radius: .25rem;
  box-shadow: $base-shadow;
  min-height: 1vh;
  max-width: 60rem;

  display: flex;
  flex-direction: column;
  @include breakpoint("lg") {
    flex-direction: row;
  }
  
  .article__text-content { flex-grow: 1; }

  .article__header { padding-bottom: 1rem; }

  .article__body {
    padding-right: 1rem;
    margin-bottom: 2rem;
    @include breakpoint("lg") {
      margin-bottom: 0;
    }
  }

  .article__button-container {
    min-width: 8rem;
    height: 100%;
    max-height: 500px;
    @include breakpoint("md") {
      max-width: 12rem;
    }
  }
}

// ----- Article Full (displayMode: "full")
.article--full {
  width: 100% !important;
  // min height as height cannot be animated
  min-height: 50vh;
  border-top: .25rem solid $color--brand-red;
  border-bottom: .25rem solid $color--brand-red;

  .article__text-content {
    @include set-grid-span(12);
    @include breakpoint("md") {
      @include set-grid-span(10);
    }
    @include breakpoint("lg") {
      @include set-grid-span(8);
    }
    margin: 0 auto;
  }

  .article__header { margin-bottom: 1rem; }

  p { margin-bottom: .75rem; }

  .article__button-container { max-height: 0px; }
  
  .article__read-more-link {
    opacity: 0;
    pointer-events: none;
  }
}


// animate articles on page load
@keyframes slide-left-fade-in-button {
  from { opacity: 0; transform: translate(1rem, -50%); }
  to { opacity: 1; transform: translate(0, -50%); }
}

.slide-left-fade-in--button {
  animation: slide-left-fade-in-button .4s forwards;

}