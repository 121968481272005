.page {
  // ensure footer is always pushed to the bottom
  min-height: 100vh;
  position: relative;

  .main-menu {
    position: fixed;
    z-index: $zindex-foreground;
  }

  .footer {
    position: absolute;
    bottom: 0;
  }
}

// add padding to pages that don't have image banner at the top
// this compensates for the height of the menu
.page--padding-top main { padding-top: $menu-dark-height; }