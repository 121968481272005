@import url('https://fonts.googleapis.com/css?family=Bree+Serif&display=swap');
$font-family-serif: 'Bree Serif', serif;

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900&display=swap');
$font-family-sans-serif: 'Source Sans Pro', sans-serif;

@import url('https://fonts.googleapis.com/css?family=Sacramento&display=swap');
$font-family-cursive: 'Sacramento', cursive;


$font-weight-bold:              600;
$font-weight-black:             900;
$font-weights: (
  "bold": $font-weight-bold,
  "black": $font-weight-black
);

/// overwrite loriss colors
$color--dark:                 #191919;
/// setup local colors 
$color--neon-blue:            #2271D3;
$color--neon-blue-bright:     #2F9FFF;
$color--neon-mulberry:        #C64B8C;
$color--neon-mulberry-bright: #D781AE;
$color--brand-red:            #FF073A;
$color--grey:                 #F8F8F8;
$colors: (
  "neon-blue": $color--neon-blue,
  "neon-blue-bright": $color--neon-blue-bright,
  "neon-mulberry": $color--neon-mulberry,
  "neon-mulberry-bright": $color--neon-mulberry-bright,
  "grey": $color--grey
);

// local variables
$menu-dark-height:                   3.5rem;
$text-glow--white:              0 0 .5rem #fff;
$text-glow--neon-blue:          0 0 .25rem $color--neon-blue;
$text-glow--brand-red:          0 0 .25rem $color--brand-red;

$shadow-hover:                  0 3px .375rem 0 rgba(0, 0, 0, .7);
$shadows: (
  "hover": $shadow-hover
);

$icon--xs:                      1.4rem;
$icon--sm:                      2rem;
$icon--md:                      3rem;
$icon--lg:                      3.8rem;
$icon--xl:                      4.6rem;
$icon-sizes: (
  "xs": $icon--xs,
  "sm": $icon--sm,
  "md": $icon--md,
  "lg": $icon--lg,
  "xl": $icon--xl
);

@import 'loriss';
@import './styles/mixins';
@import './styles/animations';
@import './library/library.scss';

.page-banner.text-container,
.filters.text-container,
.search-form__form.text-container {
  &--narrow { max-width: 60rem; }
  &--very-narrow { max-width: 45rem; }
}

html {
  scroll-behavior: smooth;
}

.App {
  min-height: 100vh;
}

@import './styles/pages';