.main-menu {
  width: 100%;
  height: 5rem;
  box-shadow: none;
  background-color: rgba($color--dark, 0);
  button {
    display: none;
  }

  &__items {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  transition: height .2s ease-out, color .2s ease-out, box-shadow .2s ease-out, background-color .2s ease-out;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2.5rem;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 50%;
      width: 0;
      border-bottom: .25rem solid;
      transform: translateX(-50%);

      transition: width .2s ease-out;
    }
  }
}

// DIFFERENT MENU COLOR THEMES
// ----- Menu Light
.main-menu--light {
  background: linear-gradient(
    to bottom,
    rgba($color--dark, .98),
    rgba($color--dark, 0)
  );
  a {
    color: $color--light;
    z-index: 1;

    &::after {
      border-color: $color--brand-red;
    }
    @include breakpoint("md") {
      &:hover::after {
        width: 70%;
      }
    }
  }
}
// ----- Menu Dark
.main-menu--dark {
  height: $menu-dark-height;
  background-color: rgba($color--dark, .98);
  box-shadow: $shadow-bottom;

  a {
    color: $color--light;
    z-index: 1;
    
    &::after {
      border-color: $color--brand-red;
    }
    &:hover::after {
      width: 70%;
    }
  }
}

// ----- Menu Collapsed
// for small breakpoints
.menu--collapsed {
  button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: $menu-dark-height;
    padding: 0 1.5rem;
    background: $color--dark;
    border: 1px solid $color--dark;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:hover {
      // make all lines in menu icon same width on hover
      div[class^="menu-icon--"] {
        width: 90%;
      }
    }
  }

  height: auto;
  display: flex;
  flex-direction: column;
  .main-menu__items {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    top: $menu-dark-height;
    left: -100%;
    overflow: hidden;
    background: $color--dark;
    
    a {
      background: $color--dark;
      padding: 1.5rem 0;

      &::after {
        display: none;
      }
    }

    transition: left .3s;
  }
  
  // show menu on small breakpoints and overlay the
  // rest of the site with a shadow
  .menu-items-onscreen {
    left: 0;
    box-shadow: 0 800px 0 800px rgba($color--dark, .6);
  }
}

// ----- Menu Icon
.menu-icon {
  position: relative;
  width: 2rem;
  height: 2rem;

  // styles common to all 3 lines of the icon
  > div {
    position: absolute;
    right: 0;
    height: .2rem;
    background-color: $color--light;
    transition: width .2s ease;
  }

  // ----- adjust individual line widths
  &--top {
    width: 100%;
    top: 10%;
  }

  &--middle {
    width: 60%;
    top: 50%;
    transform: translateY(-50%);
  }

  &--bottom {
    bottom: 10%;
    width: 80%;
  }
}