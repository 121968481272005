.cover-image {
  // background color shows whilst image is loading
  background-color: $color--light;
  
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
