.quote-block {
  position: relative;
  width: 100%;
  padding: 3rem 0;
  height: 100%;
  overflow: hidden;

  // background image
  .cover-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -100;

    img {
      opacity: .6;
    }
  }

  &__text-content {
    @include set-grid-span(12);
    padding: 0 2rem;
    margin: 0 auto;

    @include breakpoint("md") {
      @include set-grid-span(8);
    }

    @include breakpoint("lg") {
      @include set-grid-span(6);
      max-width: map-get($theme-breakpoints, "xl") / 2;
    }

    &--quotation {
      font-family: $font-family-cursive;
      font-size: $font-size-base * 2;
      text-align: center;
      text-shadow: $text-glow--white;
    }

    &--source {
      margin-top: .5rem;
      text-align: center;
      text-transform: uppercase;
      text-shadow: $text-glow--white;
      color: $color--brand-red;
      font-weight: $font-weight-heavy;
    }
  }
}
