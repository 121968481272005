.contact-page {
  padding-top: 3rem;
  p {
    margin-bottom: 1rem;
  }
  a {
    font-family: $font-family-serif;
    color: $color--brand-red;
    transition: color .2s ease;
    &:hover {
      color: darken($color--brand-red, 10%);
    }
  }
  
  .icons-block {
    padding: 2rem 0;
  }
}