.search-form {
  padding: 1rem 0 3rem;

  &__form {
    display: flex;
    flex-direction: column;
    @include breakpoint("md") {
      flex-direction: row;
    }

    // this contains the input and label:
    &-field {
      @include set-grid-span(12);
      @include breakpoint("md") {
        @include set-grid-span(9);
      }
      position: relative;
      flex-grow: 1;

      label {
        cursor: text;
        position: absolute;
        left: .75rem;
        top: 50%;
        transform: translateY(-50%);
        font-family: $font-family-sans-serif;
        font-size: $font-size-base;
        color: $color--mid;

        transition: font-size .2s ease-out, top .2s ease-out;
      }
    }

    input {
      width: 100%;
      font-size: 1rem;
      font-family: $font-family-sans-serif;
      padding: .75rem;
      padding-top: 1.5rem;
      padding-bottom: .25rem;
      border: none;
      border-bottom: .125rem solid $color--mid;
      // this included for iOS Safari:
      border-radius: 0px;
      background-color: rgba($color--mid, .1);

      transition: border-color .2s ease;

      &:focus {
        outline: none;
      }
    }

    // make label small and move it out of the way on focus
    // and when the input has value
    input:focus ~ label, input:not([value=""]) ~ label {
      top: 20%;
      font-size: .8rem;
    }
  }

  &__submit-button {
    cursor: pointer;
    position: relative;
    margin-top: 1rem;
    @include set-grid-span(12);
    @include breakpoint("md") {
      @include set-grid-span(3);
      margin-left: 1rem;
      margin-top: 0;
    }
    min-height: 3rem;
    z-index: 1;

    border: 2px solid $color--dark;
    border-radius: .25rem;
    font-family: $font-family-serif;
    font-size: $font-size-base;
    text-transform: uppercase;
    color: $color--dark;
    text-shadow: $text-glow--white;

    background-color: $color--light;

    transition: color .2s ease-out, border-color .2s ease-out, background-color .2s ease-out;
    &:hover {
      color: $color--light;
      background-color: $color--dark;
    }
  }
}

// form input states
.form--valid {
  input {
    color: $color--dark;
    border-color: $color--brand-red;
  }
}

.form--error {
  input {
    color: $color--dark;
    border-color: $color--mid;
  }
}

button[disabled=true] {
  color: $color--mid;
  border-color: $color--mid;
  
  &:hover {
    background-color: #fff;
    color: $color--mid;
  }
}

// Text that shows search term with a link to
// "show all"
.search-form__search-text {
  margin-top: .5rem;

  span {
    cursor: pointer;
    color: $color--brand-red;
    font-family: $font-family-serif;
    text-decoration: underline;
    transition: color .2s ease-out;
    &:hover {
      color: darken($color--brand-red, 10%);
    }
  }
}