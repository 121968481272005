.hero {
  position: relative;
  overflow: visible;
  .cover-image {
    height: 100%;
    filter: saturate(0);
  }

  .title-gradient {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
    overflow: visible;

    h1 {
      font-family: $font-family-cursive;
      font-size: 3.8rem;
      @include breakpoint("md") {
        font-size: 5rem;
      }
      font-weight: 400;
      color: $color--light;
      text-transform: unset;
      line-height: 1;

      animation: glow 1.4s ease-in-out infinite alternate;
    }
  }
}

@keyframes glow {
  from {
    text-shadow:  0 0 .5rem $color--light,
                  0 0 1rem $color--light,
                  0 0 1.5rem $color--brand-red,
                  0 0 2rem $color--brand-red,
                  0 0 2.5rem $color--brand-red,
                  0 0 3rem $color--brand-red;
  }
  to {
    text-shadow:  0 0 1rem $color--light,
                  0 0 1.5rem darken($color--brand-red, 5%),
                  0 0 2rem darken($color--brand-red, 5%),
                  0 0 2.5rem darken($color--brand-red, 5%),
                  0 0 3rem darken($color--brand-red, 5%),
                  0 0 3.5rem darken($color--brand-red, 5%),
  }
}