.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 3rem 2rem;

  background-color: $color--dark;

  p {
    color: darken($color--light, 10%);
    font-size: $font-size-small;
  }
  a {
    color: $color--brand-red;
    &:hover {
      color: darken($color--brand-red, 10%);
    }
  }
}
