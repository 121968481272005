.icon {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
}


// Icon Sizes
@each $size, $value in $icon-sizes {
  .icon--#{$size} {
    width: $value;
    height: $value;
  }
}