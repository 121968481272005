.icons-block {
  display: flex;
  overflow: visible;

  .icon {
    margin-right: 3rem;
    opacity: 0;
    transform: translateX(-.5rem);
    &:nth-last-child(1) {
      margin-right: 0;
    }

    img {
      transition: transform .2s ease-out;
      &:hover {
        transform: translateY(-.25rem);
      }
    }
  }
}

// ----- icons animation
@keyframes icon-in {
  from { opacity: 0; transform: translateX(-.5rem); }
  to { opacity: 1; transform: translateX(0); }
}

.icon-in { animation: icon-in $animation-duration-medium forwards; }