.page-banner {
  padding: 3rem 0 2rem;
  &__text {
    font-size: $font-size-lead;
    width: 100%;
    p {
      font-size: inherit;
    }
  }
}
