.title-gradient {
  width: fit-content;
  opacity: 0;
  h1, h2, h3,
  h4, h5, h6 {
    // background-color: $color--neon-blue-bright;
    // border: .25rem solid $color--dark;
    box-decoration-break: clone;
    color: inherit;
    display: inline;
    font-family: $font-family-serif;
    font-weight: 700;
    line-height: 3;
    padding: .5rem 0;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

// create shadow boxes as pseudo elements of heading tag
.title-gradient.shadows {
  overflow: visible;
  position: relative;
  h1::before, h2::before, h3::before,
  h4::before, h5::before, h6::before {
    background: rgba($color--neon-blue-bright, .5);
    box-shadow: 0 0 1rem .125rem rgba($color--neon-blue-bright, 1);
    content: "";
    height: 100%;
    position: absolute;
    right: .5rem;
    top: -.75rem;
    width: 80%;
    z-index: -10;

    animation: animate-before $animation-duration-medium forwards;
  }

  h1::after, h2::after, h3::after,
  h4::after, h5::after, h6::after {
    background: rgba($color--neon-mulberry, .5);
    bottom: -1rem;
    box-shadow: 0 0 1rem .125rem rgba($color--neon-mulberry, 1);
    content: "";
    height: 90%;
    left: .5rem;
    position: absolute;
    width: 90%;
    z-index: $zindex-behind;

    animation: animate-after $animation-duration-medium forwards;
  }
}

// ----- animate shadows
@keyframes animate-before {
  from {
    opacity: 0;
    transform: rotate(-6deg) translateY(2rem);
  }
  to {
    opacity: 1;
    transform: rotate(-6deg) translateY(0);
  }
}

@keyframes animate-after {
  from {
    opacity: 0;
    transform: rotate(-6deg) translateY(-2rem);
  }
  to {
    opacity: 1;
    transform: rotate(-6deg) translateY(0);
  }
}