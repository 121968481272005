.image-and-text {
  display: flex;
  flex-flow: row wrap;
  overflow: visible;
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;
  // set width for two main sections
  > * {
    @include set-grid-span(12);
    @include breakpoint("lg") {
      @include set-grid-span(6);
    }
  }

  &__contents {
    height: fit-content;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 1;

    // set width for all props.children
    > * {
      width: 100%;
      max-width: 34rem;
    }
    p {
      margin-bottom: 1.5rem;
    }

    .icons-block {
      margin-bottom: 2rem;
    }
  }

  .cover-image {
    border: none;
    order: 0;
    img {
      transform: scale(1.1);
      filter: saturate(0);
    }
    @include breakpoint("lg") {
      border: 2px solid $color--dark;
      border-bottom-width: 0;
      order: 2;
    }
  }
}

// animate image on intersection
@keyframes image-focus {
  from { transform: scale(1.05); }
  to { transform: scale(1); }
}

.image-focus {
  animation: image-focus $animation-duration-slow forwards;
}