.filters {
  display: flex;
  padding: 0;

  &-title {
    text-transform: uppercase;
    color: $color--mid;
    padding-right: 2rem;
  }

  &__filters-list {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    list-style-type: none;
    font-family: $font-family-serif;
    li {
      text-transform: uppercase;
      color: rgba($color--brand-red, .7);
      text-decoration: none;
      padding: 0 1rem;
      transition: color .2s ease;

      &:hover {
        color: rgba($color--brand-red, 1);
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
