.card-block {
  width: 100%;
  background-color: rgba($color--grey, 1);
  border-bottom: 1px solid $color--brand-red;

  .card-block__cards-container {
    width: 90%;
    max-width: 47rem;
    margin: 0 auto;
    padding: 4rem 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    overflow: visible;
  }

  .card {
    background-color: #fff;
    width: 100%;
    max-width: 100% !important;
    margin-bottom: 2rem;
    z-index: 1;
    opacity: 0;
    transform: translateX(-1rem);

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    @include breakpoint("sm") {
      width: 46%;
    }
    @include breakpoint("md") {
      width: 30%;
      margin-bottom: 0;
    }
  }
}
